const parseObjections = (objectionsText) => {
  // const objectionPattern = /Objection:\s*"([^"]+)".+?What the salesperson said:\s*"([^"]+)".+?Was the objection handled well:\s*(Yes|No).+?How could the objection have been handled better:\s*(.+?)(?=Objection:|$)/gs;
  const objectionSegments = objectionsText.split('Objection:').slice(1); // Split by 'Objection:' and remove the first empty segment
  const objections = [];

  objectionSegments.forEach(segment => {
      const responseStart = segment.indexOf('What the salesperson said:');
      const ratingStart = segment.indexOf('Was the objection handled well:');
      const improvementStart = segment.indexOf('How could the objection have been handled better:');
      const transitionStart = segment.indexOf('What did the salesperson say to transition the conversation back to the script:');

      const objection = segment.slice(0, responseStart).replace(/"/g, '').trim();
      const response = segment.slice(responseStart, ratingStart).replace(/What the salesperson said:/, '').replace(/"/g, '').trim();
      const rating = segment.slice(ratingStart, improvementStart).replace(/Was the objection handled well:/, '').replace(/"/g, '').trim();
      const improvement = segment.slice(improvementStart, transitionStart).replace(/How could the objection have been handled better:/, '').replace(/"/g, '').trim();
      const transition = segment.slice(transitionStart).replace(/What did the salesperson say to transition the conversation back to the script:/, '').replace(/"/g, '').trim();

      objections.push({ objection: objection, response: response, rating: rating, improvement: improvement, transition: transition });

  });
  return objections;
}

function formatNameForDisplay(name){
  if (name == null) {
    return 'Team'
  }
  return name.substring(0,1).toUpperCase() + name.substring(1)
}

export {
    parseObjections,
    formatNameForDisplay
}