import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase.js';
import { MdMic } from 'react-icons/md';

const ManagerView = () => {
  const teamId = 'teamDawgs'
  const date = '2023-12-6'

  const navigate = useNavigate();
  const [teamReport, setTeamReport] = useState([]);
  const [shoutouts, setShoutouts] = useState([]);
  const [repReport, setRepReport] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(new Date()); // State to store the last updated date
  const [showConversations, setShowConversations] = useState({
    incorrectROI: false,
    notPersistent: false,
  });

  // Function to toggle conversation visibility
  const toggleConversations = (section) => {
    setShowConversations((prevShow) => ({
      ...prevShow,
      [section]: !prevShow[section],
    }));
  };

  useEffect(() => {
    const checkForTeamReport = async () => {
      const docId = `${teamId}-${date}`
      console.log('Checking for team report...')
      const docRef = doc(collection(db, "daily-user-report"), docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        console.log('Daily team recommendations exists')
        setTeamReport(docSnapshot.data().recommendations)
        setShoutouts(docSnapshot.data().shoutouts)
      } else {
      console.log('Daily team recommendations doesnt exist.')
      }
    };
    checkForTeamReport();
    const lastUpdatedTimestamp = new Date();
    setLastUpdated(lastUpdatedTimestamp);

  }, []);
  
  function handleSeeMoreClick(nameId) {
    console.log(`Scrolling to ${nameId}`); // Log to ensure the function is called
    const element = document.getElementById(nameId);
    if (element) {
      // Scroll element into view
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
      // Your fixed header's height, adjust the value accordingly
      const headerOffset = 60; // Example header height in pixels
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
      // Scroll to the offset position
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  const formattedLastUpdate = lastUpdated.toLocaleString();

  return (
    <div className="flex flex-col items-center w-full min-h-screen pt-8">
      {/* Fixed header */}
      <div className="w-full h-12 bg-gray-100 flex items-center px-4 border-b border-gray-300 fixed top-0 z-10">
        <span className="text-blue-900 text-lg font-bold">Fielder</span>
      </div>
      
      {/* Rest of the content should start below the fixed header and notification */}
      <div className='w-full px-4 md:px-12 mt-20'>
        <div className="flex items-center mb-10 mx-8 bg-white shadow-md rounded-lg border border-gray-200 p-2">
            <MdMic className="text-blue-600 text-6xl sm:text-8xl mr-4" />
            <div>
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                    Daily Team Summary
                </h2>
                <p className="text-sm sm:text-base text-gray-600 mt-1">
                    Yesterday, your team recorded <span className="font-semibold">60 conversations</span> and set <span className="font-semibold">7 appointments</span>. Our AI compiled the report for you.
                </p>
            </div>
        </div>

      {/* Where the team is losing deals section */}
      <div className="w-full mt-10">
        <div className="max-w-6xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Where the team is losing deals</h2>
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="p-6">
              {/* Empathy Lack Section */}
              <div className="mb-6 pb-6 border-b">
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Not showing enough empathy</h3>
                <div className="text-sm text-gray-600 mb-3">Based on 15 conversations</div>

                <button onClick={() => toggleConversations('empathyDeficit')}
                        className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600">
                  <span>{showConversations.empathyDeficit ? 'Hide' : 'Show'} conversations</span>
                </button>
                {showConversations.empathyDeficit && (
                  <div className="mt-4">
                    {/* Conversation snippet for "Lack of empathy" */}
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">James</div>
                      <div className="text-gray-700 text-sm mb-2">Failed to empathize with prospect's loss, continued sales pitch.</div>
                      <div className="text-gray-500 mb-2 text-sm">[00:04 - 01:09] salesperson: "hi how's it going what's that making sure i'm in the right place is this still the residence yes perfect i'm following up on a notice that was sent out to in regards to the second phase of your guys' market project did you get a chance to read and respond back to the notices he's dead do you know that that would make sense why he hasn't responded back to our notices"</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-James`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                        
                        Go to rep report
                      </button>                    
                    </div>
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Jill</div>
                      <div className="text-gray-700 text-sm mb-2">Failed to address prospect's indifference to problem.</div>
                      <div className="text-gray-500 mb-2 text-sm">[00:05 - 00:15] salesperson: "yes sir i'll be brief basically the reason we're in the neighborhood there was a newsletter sent out basically detailing a fifteen percent increase on the duke energy bills" [00:15 - 00:17] prospect: "yeah i'm not even worried about that" [00:17 - 00:18] salesperson: "oh okay"</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Jill`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                         
                        Go to rep report
                      </button>
                    </div>
                  </div>
                )}
              </div>
            
              {/* Adaptability &  Persistence*/}
              <div className="mb-8">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Too focused on the offering and not on the customer's pain</h3>
                  <div className="text-sm text-gray-600 mb-3">Based on 10 conversations</div>
                  <button onClick={() => toggleConversations('adaptabilityPersistence')}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600">
                    <span>{showConversations.adaptabilityPersistence ? 'Hide' : 'Show'} conversations</span>
                  </button>
                  {showConversations.adaptabilityPersistence && (
                    <div className="mt-4">
                    {/* Conversation snippet for "Lack of empathy" */}
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Tom</div>
                      <div className="text-gray-700 text-sm mb-2">Failed to establish trust and credibility. Used jargon and made unrealistic promises.</div>
                      <div className="text-gray-500 mb-2 text-sm">[00:08 - 00:22] salesperson: we're with ion we're just filming for training purposes so if you don't want us to film you can just say no no we won't bother with that okay essentially why i'm out here today is actually xl's net metering program just kinda informing folks about that have you ever heard any literature on that or heard about that [01:07 - 01:09] salesperson: if there's roi day one what about our program [01:09 - 01:11] salesperson: i wouldn't believe you [01:11 - 01:15] salesperson: you're a shady salesman yep</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Tom`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                         
                        Go to rep report
                      </button>                    
                    </div>
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Tom</div>
                      <div className="text-gray-700 text-sm mb-2">Failed to listen and respond to prospect's concerns. Overly focused on product features.</div>
                      <div className="text-gray-500 mb-2 text-sm">[01:20 - 01:23] salesperson: so you'll see your neighbor chris you can actually see [01:23 - 01:25] prospect: yep i saw you guys putting them in there earlier today [01:25 - 01:34] salesperson: installed there so the way that program works is there's no upfront cost essentially what happens is day one we're installing the panels free to them so they're not putting any money down they're not paying anything</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Tom`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                         
                        Go to rep report
                      </button>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Where the team is crushing it section */}
      <div className="w-full mt-10">
        <div className="max-w-6xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Where the team is crushing it</h2>
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            <div className="p-6">
              {/* Customer Understanding */}
              <div className="mb-6 pb-6 border-b">
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Listening to the customer</h3>
                <div className="text-sm text-gray-600 mb-3">Based on 20 conversations</div>

                <button onClick={() => toggleConversations('customerUnderstanding')}
                        className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600">
                  <span>{showConversations.customerUnderstanding ? 'Hide' : 'Show'} conversations</span>
                </button>
                {showConversations.customerUnderstanding && (
                  <div className="mt-4">
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Jill</div>
                      <div className="text-gray-700 text-sm mb-2">Maintains respectful tone, seeks to understand prospect's perspective, offers value.</div>
                      <div className="text-gray-500 mb-2 text-sm">[03:42 - 03:49] salesperson: yeah so that's why i'm here i'm not here to get like get you to make a decision or anything i just wanna show you a competitive quote to tesla now [03:49 - 03:51] prospect: actually it's not gonna be competitive [03:51 - 03:56] salesperson: okay because it's not gonna be competitive on on what standpoint just so i can understand better</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Jill`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                         
                        Go to rep report
                      </button>                    
                    </div>
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Jill</div>
                      <div className="text-gray-700 text-sm mb-2">Confirms prospect's details, respects prospect's time, schedules follow-up.</div>
                      <div className="text-gray-500 mb-2 text-sm">[02:34 - 02:48] salesperson: cool first remind me your name david david david what was your last name what typically is the best time of day to get in contact with you best part of the day is better okay so i'm gonna put in my calendar the monday at three o'clock</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Jill`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                         
                        Go to rep report
                      </button>
                    </div>
                  </div>
                )}
              </div>
            
              {/* Effective Raport Building */}
              <div className="mb-8">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">Building rapport</h3>
                  <div className="text-sm text-gray-600 mb-3">Based on 12 conversations</div>
                  <button onClick={() => toggleConversations('effectiveRaportBuilding')}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600">
                    <span>{showConversations.effectiveRaportBuilding ? 'Hide' : 'Show'} conversations</span>
                  </button>
                  {showConversations.effectiveRaportBuilding && (
                    <div className="mt-4">
                    {/* Conversation snippet for "Lack of empathy" */}
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Tom</div>
                      <div className="text-gray-700 text-sm mb-2">Builds rapport by celebrating prospect's personal news, asks qualifying questions, ends conversation on a positive note.</div>
                      <div className="text-gray-500 mb-2 text-sm">[03:55 - 03:58] salesperson: oh congratulations yes thank you is that your first woo hoo yes hey boy or girl [03:58 - 04:04] salesperson: boy or girl it's a boy high five yes bro it's awesome [04:04 - 04:09] prospect: thank you so i'll be resuming my office so i'll be driving [04:09 - 04:29] salesperson: i'm spending more charge okay well why don't we do this qualifying questions it's the name of the game right any good rep is has his questions lined out give it back to the homeowner what's a good phone number for you [04:29 - 04:33] prospect: eight seven three [04:33 - 04:47] salesperson: is this typically a good time of day for you alright well it was a pleasure to meet you congratulations on your baby you'll see us up and down the neighborhood over the next couple of weeks so if you see us i promise we're not breaking into people's cars or just doing our job alright have a great one</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Tom`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                          
                        Go to rep report
                      </button>                    
                    </div>
                    <div className="mt-6">
                      <div className="mb-2 text-sm font-bold">Tom</div>
                      <div className="text-gray-700 text-sm mb-2">Addresses prospect's skepticism, highlights unique selling point of product.</div>
                      <div className="text-gray-500 mb-2 text-sm">[01:07 - 01:09] salesperson: if there's roi day one what about our program [01:09 - 01:11] salesperson: i wouldn't believe you [01:11 - 01:15] salesperson: you're a shady salesman yep [01:15 - 01:16] prospect: saw you guys [01:16 - 01:20] salesperson: the nice part about our program it's not a leasing program it's an ownership program</div>
                      <button
                        onClick={() => handleSeeMoreClick(`report-Tom`)}
                          className="text-blue-600 text-sm hover:text-blue-800 visited:text-purple-600"
                      >                          
                        Go to rep report
                      </button>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* "Where the team is losing deals" section with card background */}
      {/* <div className="w-full overflow-x-hidden">
        <div className="px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold mb-5 text-gray-700">Where the team is losing deals</h2>
          <div className="border rounded-xl p-6 shadow-md my-4 bg-white">
            {teamReport.map((recommendation, index) => (
              <div key={index} className="py-4 border-b last:border-b-0">
                <div className="mb-2 text-lg font-bold">{recommendation.name}</div>
                <div className="text-gray-700 mb-2">{recommendation.note}</div>
                <div className="text-gray-500 italic mb-4 text-sm">{recommendation.evidence}</div>
                <button
                  onClick={() => handleSeeMoreClick(`report-${recommendation.name.replace(/\s+/g, '')}`)}
                  className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  Details
                </button>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* "Where the team is winning deals" section with a single card background */}
      {/* <div className="w-full mt-10 overflow-x-hidden">
        <div className="px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold mb-5 text-gray-700">Where the team is winning deals</h2>
          <div className="border rounded-xl p-6 shadow-md my-4 bg-white">
            {shoutouts.map((shoutout, index) => (
              <div key={index} className="py-4 border-b last:border-b-0">
                <div className="mb-2 text-lg font-bold">{shoutout.name}</div>
                <div className="text-gray-700 mb-2">{shoutout.note}</div>
                <div className="text-gray-500 italic mb-4 text-sm">{shoutout.evidence}</div>
                <button
                  onClick={() => handleSeeMoreClick(`report-${shoutout.name.replace(/\s+/g, '')}`)}
                  className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  Details
                </button>
              </div>
            ))}
          </div>
        </div>
      </div> */}

    {/* Team Member Report Section */}
    <div className="w-full mt-10 overflow-x-hidden">
      <div className="px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold mb-5">Report for each rep</h2>
        <div className="flex flex-wrap justify-center gap-6">
          {repReport.map((member, index) => (
            <div key={member.id} id={`report-${member.name.replace(/\s+/g, '-')}`} className="border rounded-xl p-6 shadow-md my-4 bg-white">
              <h3 className="text-lg font-bold text-gray-800 mb-4">{member.name}</h3>
              <div className="mb-4">
                <div className="font-medium">Doors Knocked:</div>
                <div>{member.doorsKnocked}</div>
              </div>
              <div className="mb-4">
                <div className="font-medium">Appointments Set:</div>
                <div>{member.appointmentsSet}</div>
              </div>
              <div className="mb-4">
                <div className="font-medium">Avg Convo Length (mins):</div>
                <div>{member.averageConvoLengthMins}</div>
              </div>
              <div className="mb-4">
                <div className="font-medium">Where they are losing deals:</div>
                {member.feedback.map((item, feedbackIndex) => (
                  <div key={feedbackIndex} className="mt-2">
                    <div>{item.comment}</div>
                    <div className="text-gray-400" style={{ fontSize: 'smaller' }}>
                      "{item.evidence}"
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-4">
                <div className="font-medium">Where they are crushing it:</div>
                {member.strengths.map((item, strengthIndex) => (
                  <div key={strengthIndex} className="mt-2">
                    <div>{item.comment}</div>
                    <div className="text-gray-400" style={{ fontSize: 'smaller' }}>
                      "{item.evidence}"
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  </div>
  );
}

export default ManagerView;