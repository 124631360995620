import React from 'react';
import { Transition } from '@headlessui/react';

const Testimonials = ({ logos }) => {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      <h2 className="text-2xl font-semibold text-center mb-6">
        Used by reps from top solar teams across North America
      </h2>
      
      <div className="flex -space-x-4">
        {logos.map((logo, index) => (
          <Transition
            key={index}
            show={true}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-75"
            enterTo="transform scale-100"
          >
            <img 
              className={`h-16 w-16 rounded-full border-4 border-white shadow-lg hover:shadow-2xl transform hover:scale-110 z-${10 + index}`}
              src={logo}
              alt={`Logo ${index + 1}`}
              style={{ zIndex: 10 + index }}
            />
          </Transition>
        ))}
      </div>

      <p className="mt-6 text-xl text-center hover:text-blue-600">
        Start now → Win more deals tomorrow.
      </p>
    </div>
  );
};

export default Testimonials;
