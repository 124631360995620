const AnalysisStates = {
  WaitingForData: 'WaitingForData',
  DataComplete: 'DataComplete',
  UploadingData: 'UploadingData',
  DoingAnalysis: 'DoingAnalysis',
  AnalysisComplete: 'AnalysisComplete'	
}


export {
	AnalysisStates,
}