import React from 'react';

function IntroView() {
    return (
        <div className='flex flex-col items-center p-2'>
            <div className='text-center'>
                <p className='text-lg md:text-2xl text-gray-800 font-bold italic my-2 bg-yellow-300 rounded-lg px-2 py-1'>
                    TIME TO START KNOCKING
                </p>
            </div>
            <div className='w-full'>
                <p className='text-2xl md:text-2xl text-gray-800 font-bold my-2 rounded px-2 py-1'>
                    🏁 Tap start, and go knock some doors!
                </p>
                <p className='text-2xl md:text-2xl text-gray-800 font-bold my-2 rounded px-2 py-1'>
                    👂 Fielder knows when your conversations start and finish
                </p>
                <p className='text-2xl md:text-2xl text-gray-800 font-bold my-2 rounded px-2 py-1'>
                    💰 Keep an eye on the Fielder app to see your rewards!
                </p>
            </div>
        </div>
    );
}

export default IntroView;