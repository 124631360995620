
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import NameInput from '../InputComponents/NameInput'
import FileUpload from '../InputComponents/FileUpload'
import AudioInput from './AudioInput';
import {AnalysisStates} from '../Constants'
import { storeFile } from '../Firebase';

function UploadData() {

  // set up
  const [analysisState, setAnalysisState] = useState(AnalysisStates.WaitingForData)
  const [audioURL, setAudioURL] = useState(null)
  const [audioBlob, setAudioBlob] = useState()
  const [notes, setNotes] = useState();
  const [name, setName] = useState();
  const [audioFile, setAudioFile] = useState();
  const navigate = useNavigate();
  
  // run
  const [formComplete, setFormComplete] = useState(false)

  const uploadAudio = async () => {
    if (audioBlob) {
      try {
        setAnalysisState(AnalysisStates.UploadingData)
        const url = await storeFile(audioBlob, 'recording.wav')
        setAudioURL(url)
        console.log('Upload successful.');
        setAnalysisState(AnalysisStates.DoingAnalysis)
        // beginAnalysis()

      } catch (error) {
        console.error('Error uploading:', error);
      }
    }
  };

  const beginAnalysis = async (url) => {
    const endpoint = "https://fieldsales-backend-707ce0b7ef1a.herokuapp.com/initiate";
    const data = {
        "fileURL": url,
        "notes": notes,
        "name": name.toLowerCase().trim()
    };
    console.log("Sending data: ", data)

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {

      setAnalysisState(AnalysisStates.AnalysisComplete)
      const responseBody = await response.json();
      console.log(`Successfully sent request. Received data back: ${responseBody}. Redirecting`)
      navigate(`/result/${responseBody.doc_id}`);
    } else {
      // Handle the error
      console.error('Failed to start experiment');
    }
  }


  const test = async () => {
    try {
      setAnalysisState(AnalysisStates.UploadingData)
      const url = await storeFile(audioFile, 'audio_file.mp3')
      setAudioURL(url)
      console.log('Upload successful. Starting analysis');
      setAnalysisState(AnalysisStates.DoingAnalysis)
      beginAnalysis(url)

    } catch (error) {
      console.error('Error uploading:', error);
    }
  }



  return (
    <div className="flex flex-col items-center min-h-screen min-w-screen">
      <div className="w-full h-12 bg-gray-100 flex items-center px-4 border-b-1 border-gray-300 fixed top-0">
        <span className="text-blue-900 text-lg font-bold">Fielder</span>
      </div>
      <UploadModal  
        audioBlob={audioBlob} setAudioBlob={setAudioBlob} 
        notes={notes} setNotes={setNotes}
        uploadAudio={uploadAudio}
        analysisState={analysisState}
        setAnalysisState={setAnalysisState}
        audioFile={audioFile}
        setAudioFile={setAudioFile}
        submit={test}
        name={name}
        setName={setName}
      />      
  </div>
  )
}

function UploadModal({
    audioBlob, setAudioBlob, 
    notes, setNotes, 
    name, setName,
    uploadAudio,
    analysisState, setAnalysisState, 
    audioFile, setAudioFile,
    submit}) {
    return (
      <div className="flex flex-col items-center min-h-screen min-w-[50%] py-10 w-600 mx-auto">
        <div className="border border-gray-300 m-4 p-4 rounded-lg w-600 min-w-full">
          <h2 className="text-2xl font-bold mb-4">Upload your conversation</h2>
          <NameInput name={name} setName={setName} inputLabel={'Sales Rep Name'} placeholder={'John'}/>
          <NameInput name={notes} setName={setNotes} inputLabel={'Recording notes'} placeholder={'Red brick house'}/>
          {/*<AudioInput audioBlob={audioBlob} setAudioBlob={setAudioBlob} uploadAudio={uploadAudio} analysisState={analysisState} setAnalysisState={setAnalysisState}/>*/}
          <FileUpload file={audioFile} setFile={setAudioFile} />
          <div className='flex flex-row-reverse px-6'>
            {
              analysisState == AnalysisStates.WaitingForData && 
              <button className='bg-black text-white p-2 rounded' onClick={submit}>Start conversational analysis</button>
            }
            {
              analysisState == AnalysisStates.UploadingData && 
              <button className='bg-black text-white p-2 rounded disabled' onClick={submit}>Uploading data...</button>
            }
            {
              analysisState == AnalysisStates.DoingAnalysis && 
              <button className='bg-black text-white p-2 rounded disabled' onClick={submit}>Running Analysis...</button>
            }

          </div>
        </div>
      </div>
    );

}


export default UploadData;