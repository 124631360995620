import React, { useEffect, useState } from 'react';
import BoxAnimation from './BoxAnimation.jsx'
const PrizeState = {
  AVAILABLE: 'available',
  EXPIRED: 'not_available',
  ACHIEVED: 'achieved',
}


function HouseCard({ convo, index, reward_images, isMostRecent, lastWordTimestamp, endConversation, animate, sessionState, endingConversation }) {
  const [countdown, setCountdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardCountdown, setRewardCountdown] = useState(120);
  const isConversationInProgress = convo.start_time && !convo.end_time;
  const isConversationEnded = convo.start_time && convo.end_time;
  // const expiryTime = new Date(10 * 1000 + convo.expiry_time.nanoseconds / 1000000);
  const expiryTime = new Date(convo.expiry_time.seconds * 1000 + convo.expiry_time.nanoseconds / 1000000);
  const [timeLeft, setTimeLeft] = useState('');
  const [isRewardExpired, setIsRewardExpired] = useState(false);


  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const distance = expiryTime - now;
  
      if (distance < 0) {
        clearInterval(intervalId);
        setIsRewardExpired(true); // Set reward as expired
        setTimeLeft("0 seconds");
        return;
      }
  
      const minutes = Math.floor(distance / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimeLeft(`${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`);
    }, 1000);
  
    return () => clearInterval(intervalId); // Correct placement of the cleanup function
  }, [expiryTime]);  

  // set timer for 
  useEffect(() => {
    let frameId;

    // Reset loading when a new conversation starts or when isMostRecent changes
    if (!isConversationInProgress || !isMostRecent) {
      setLoading(false);
    }

    const updateCountdown = () => {
      const currentTime = new Date();
      const lastWordTime = new Date(lastWordTimestamp);
      const timeElapsed = currentTime - lastWordTime;
      const timeSinceLastWord = Math.floor(timeElapsed / 1000);
      const countdownSeconds = 10 - timeSinceLastWord;

      if (countdownSeconds <= 0) {
        if (isMostRecent && isConversationInProgress) {
          setLoading(true);
          setCountdown(null);
        }
        cancelAnimationFrame(frameId);
      } else {
        setCountdown(countdownSeconds <= 3 ? countdownSeconds : null);
        frameId = requestAnimationFrame(updateCountdown);
      }
    };

    if (isMostRecent && isConversationInProgress && lastWordTimestamp) {
      frameId = requestAnimationFrame(updateCountdown);
    }

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
    };
  }, [isMostRecent, isConversationInProgress, lastWordTimestamp]);

  return (
    <div key={index} className="flex flex-col justify-between items-center bg-white shadow-lg rounded-lg p-4 h-96 m-2">
      {/* Display House Number */}
      <div className="items-center justify-center text-sm text-gray-700">
        {`🏠 House ${index + 1}`}
      </div>
      {/* Reward Image */}

      <div className="w-40 h-40 flex items-center justify-center">
        {
          (isRewardExpired || convo.prize_status === PrizeState.EXPIRED)
          ? 
          (
            <p>Your reward expired! Don't worry, there are plenty more!</p>
          ): 
          (
            <BoxAnimation
              animate={animate}
              rewardImagePath={reward_images[convo.reward_tier][1]}
              rewardImageTitle={reward_images[convo.reward_tier][0]}
              sessionState={isMostRecent && sessionState === 'WAIT_FOR_CONVERSATION' ? 'WAIT_FOR_CONVERSATION' : 'OTHER'}
            />
          )
        }
      </div>
      {/* Display Conversation Status */}
      <div className='flex flex-col'>
        {isConversationInProgress && !isConversationEnded ? (
          <>
            <div className="flex flex-col bg-gray-500 text-white px-3 py-1 rounded text-center">
              <p>Activated</p>
              <button onClick={endConversation} className="text-gray-700 bg-gray-300 text-xs px-2 py-1 rounded text-center">
                {loading ? (
              <div className="flex animate-spin rounded-full h-3 w-3 border-b-2 border-gray-900 mt-2"></div>
            ) : countdown !== null ? (
              <p className="flex text-gray-700 bg-gray-300 text-xs px-2 py-1 rounded">
                Ending in {countdown}s
              </p>
            ) : 
            <p className="flex text-gray-700 bg-gray-300 text-xs px-2 py-1 rounded">
              End Conversation
            </p>}
              </button>
            </div>
          </>
        ) : isConversationEnded ? (
          <div className={`inline-block px-3 py-1 text-sm font-semibold text-white rounded text-center ${
            convo.outcome === null && 'bg-gray-500' ||
            convo.outcome === 'no_answer' && 'bg-yellow-400' ||
            convo.outcome === 'no_appointment' && 'bg-gray-400' ||
            convo.outcome === 'appointment_set' && 'bg-green-400'
          }`}>
            {
              convo.outcome === null ? 
              (
                'Speak with a homeowner to activate'
              )
              : 
              convo.outcome.replace('_', ' ')
            }
          </div>
        ) : ( // 
          <div>
                      
            <div className="bg-gray-500 text-white px-3 py-1 rounded text-center mt-2">
              {
                (timeLeft == '0 seconds') ?
                (
                  <p>Start pitching to activate</p>
                ): (
                  <div>
                    <p>Start pitching within</p>
                    <span className="bg-red-500 px-1 py-1 rounded font-bold text-xs">{timeLeft}</span>
                    <p>to activate</p>
                  </div>
                ) 

              }
            </div>
          </div>

        )}
      </div>
    </div>
  );
}

export default HouseCard;