import React, { useState, useEffect } from 'react';

const RotatingText = () => {
    const messages = [
        "Transcribing your conversation...",
        "Analyzing your conversation...",
        "Writing a custom response..."
    ];

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={`text-center transition-opacity duration-1000 ease-in-out`}>
          {messages[currentMessageIndex]}
        </div>
    );
};

export default RotatingText;
