import React, { useState } from 'react';

function NameInput({name, setName, inputLabel, placeholder}) {
  const handleChange = (e) => {
    setName(e.target.value);
  }

  return (

    <div className='flex flex-col m-6'>      
      <label className=' text-sm'>{`${inputLabel}:`}</label>
      <input 
        type="email" 
        value={name} 
        onChange={handleChange} 
        className='rounded-md border border-gray-300 text-lg p-2'
        placeholder={placeholder}
      />
      </div>
  );
}

export default NameInput;