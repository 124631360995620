import React, {useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingPage from './LandingPage';
import UploadData from './UploadData';
import ViewResult from './ViewResult';
import ManagerView from './ManagerView';
import RepView from './RepView';
import Motivate from './Motivate';
import DayReview from './DayReview';


function App() {
  return (
    <>
    <Router>
      <div>
        <Routes>
          <Route path="/app" element={<UploadData />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/result/:docId" element={<ViewResult />} />
          <Route path="/manager" element={<ManagerView />} />
          <Route path="/day" element={<DayReview />} />
          <Route path="/rep/:teamName/:repName" element={<RepView />} />
          <Route path="/rep/:teamName" element={<RepView />} />
          <Route path="/motivate" element={<Motivate />} />
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;

