import React, { useEffect, useState } from 'react';
import styles from './BoxAnimation.module.css'; // Ensure your CSS module has the relevant styles

function BoxAnimation({ animate, rewardImagePath, sessionState, rewardImageTitle }) {
    const [stage, setStage] = useState('start');

    useEffect(() => {
        if (animate) {
            setStage('shudder');
            setTimeout(() => setStage('explode'), 750);
        }
    }, [animate]);

    // Determine the CSS class for opacity based on the sessionState
    const opacityClass = sessionState === 'WAIT_FOR_CONVERSATION' ? styles.translucent : styles.opaque;
    return (
        <div className={styles.animationContainer}>
            <div className={`${styles.box} ${opacityClass} 
                                     ${stage === 'enlarge' ? styles.enlarge : ''} 
                                     ${stage === 'shudder' ? styles.shudder : ''}
                                     ${stage === 'explode' ? styles.explode : ''}`}
                 onAnimationEnd={() => stage === 'explode' && setStage('done')}>
                {
                    stage === 'done' ? 
                    (
                        <div>
                            <img src={rewardImagePath} alt="Reward" />
                            <p className='text-center text-sm bg-green-500 text-white font-bold rounded p-1 m-1'>Prize: {rewardImageTitle}!</p>
                        </div>
                    ) :
                    '🎁'
                }
            </div>
        </div>
    );
}

export default BoxAnimation;
