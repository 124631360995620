import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import CommandBar from './CommandBar.jsx';
import HouseCard from './HouseCard.jsx';
import IntroView from './IntroView.jsx';
import SummaryView from './SummaryView.jsx';

import { db, fetchConversationsForUser } from '../Firebase'
import { collection, doc, getDoc } from 'firebase/firestore';



const reward_images = {
    0: ['GIF', '/motivational_gifs/belfort.gif'],
    1: ['GIF', '/motivational_gifs/bibles.gif'],
    2: ['GIF', '/motivational_gifs/taylor.gif'],
    3: ['GIF', '/motivational_gifs/you_got_this.gif'],
    4: ['1 can of Red Bull', '/reward_images/red_bull.png'],
    5: ['$20 Amazon Gift Card', '/reward_images/amazon_card.png'],
    6: ['Red Lobster Dinner for 2', '/reward_images/lobster.png'],
    7: ['X-Box Controller', '/reward_images/xbox_controller.png'],
    8: ['Callaway Golf Bag', '/reward_images/golf_bag.png'],
};

// const socket = io.connect('http://localhost:2999', {
const socket = io('https://fieldsales-backend-707ce0b7ef1a.herokuapp.com', {
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax' : 5000,
    'reconnectionAttempts': 10
});

socket.on('connect', () => {
    console.log('Socket connected successfully');
});

socket.on('connect_error', (error) => {
    console.error('Connection error:', error);
});

const SessionState = {
	NOT_RECORDING: 'NOT_RECORDING',
	NO_CONVERSATIONS: 'NO_CONVERSATIONS',
	WAIT_FOR_CONVERSATION: 'WAIT_FOR_CONVERSATION',
	IN_CONVERSATION: 'IN_CONVERSATION',
}


function Motivate() {
    
	const navigate = useNavigate();
	// const [responses, setResponses] = useState([]);
	const [isRecording, setIsRecording] = useState(false);
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const audioRef = useRef();
	const sessionID = useRef(uuidv4()).current;
	const scrollRef = useRef(null);

  const [docId, setDocId] = useState(null);

  const [state, setState] = useState(SessionState.NOT_RECORDING)
  const [documentData, setDocumentData] = useState(null);
  const prevDocumentData = useRef(null);
  const [intervalId, setIntervalId] = useState(null)
  
  const collectionName = 'conversation_sequences'
  //   const documentId = 'c53cd849-9aa5-476b-80cf-c05c7728e5d6'

  const [numDoors, setNumDoors] = useState(0);
  const [numConversations, setNumConversations] = useState(0);
  const [numAppts, setNumAppts] = useState(0);

  const [lastWordTimestamp, setLastWordTimestamp] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [endingConversation, setEndingConversation] = useState(false);

  const docIdRef = useRef(docId);
  useEffect(() => {
      // Update the ref value whenever docId changes
      docIdRef.current = docId;
  }, [docId]);


  const endConversation = () => {
    console.log(`Ending conversation for document: ${docId}`);
    setEndingConversation(true);  // Optimistically set the UI to show the conversation is ending

    // Emit an event to the server to end the conversation
    socket.emit('end_conversation', { docId: docId, inProgress: false});
    
    // Optionally, you can reset the state after a delay to show the conversation has ended
    setTimeout(() => {
        setEndingConversation(false);
        setLastWordTimestamp(null); // Reset the last word timestamp
    }, 1000); // Adjust the delay based on your animation duration or other factors
};

  useEffect(() => {
      let intervalId;
  
      const startCountdown = () => {
        // Check if the current conversation is active
        const currentConvo = documentData?.convo_sequence?.[documentData.convo_sequence.length - 1];
        if (currentConvo && !currentConvo.end_time) {
          intervalId = setInterval(() => {
            const currentTime = new Date();
            const timeElapsed = currentTime - lastWordTimestamp;

            const timeSinceLastWord = Math.floor(timeElapsed / 1000);
            const countdownSeconds = (10) - timeSinceLastWord;

            if (countdownSeconds < 0) {
                clearInterval(intervalId);
                endConversation();
            } else {
                setCountdown(countdownSeconds);
            }
          }, 1000);
        } else {
          setCountdown(null);
        }
      };
  
      if (lastWordTimestamp) {
          startCountdown();
      }
  
      return () => clearInterval(intervalId);
  }, [lastWordTimestamp, documentData, endConversation]);
    
  useEffect(() => {
    if (state === SessionState.NOT_RECORDING && docId) {
      console.log(`Automatically ending conversation for document: ${docId}`);
      socket.emit('end_conversation', { docId: docId, inProgress: true});
      setLastWordTimestamp(null); // Reset the last word timestamp
    }
  }, [state, docId]);
  
  useEffect(() => {
    socket.on('doc_id', (data) => {
      setDocId(data.doc_id);
      docIdRef.current = docId;
      // startRecording();
    });

    return () => {
    socket.off('doc_id');
    };
  }, []);

  const onDocumentChange = (oldSequenceResults, newSequenceResults, resetDocIdCallback) => {
    console.log("document changed...")
    
    let conversations = 0;
    let appointments = 0;
    newSequenceResults.convo_sequence.forEach(seq => {
        if (seq.start_time !== null && seq.outcome !== 'no_answer') {
            conversations++;
            if (seq.outcome === 'appointment_set') {
                appointments++;
            }
        }
    });

    // Set the state with new counts
    setNumConversations(conversations);
    setNumAppts(appointments);

    if (newSequenceResults.finished) {
        resetDocIdCallback();
    }
    // if prize_status changed in the new sequence, 
    try {
      newSequenceResults.convo_sequence.forEach((seq, i) => {
        if (oldSequenceResults.convo_sequence[i].end_time == null && seq.end_time != null) {
          seq.animate = true
        }
      });
    } catch (error){
      console.log(error)
    }

    if(state == SessionState.NOT_RECORDING)
        return;
    // console.log("document did change...")
    // 1. If the old convo_sequences was empty and the new one is non-empty
    if (newSequenceResults.convo_sequence.length == 0) {
        setState(SessionState.NO_CONVERSATIONS);
        return; // Early return to ensure only one setState is called
    }

    // Get the last elements of the old and new convo_sequences arrays
    // const oldLast = oldSequenceResults.convo_sequence[oldSequenceResults.convo_sequence.length - 1];
    const newLast = newSequenceResults.convo_sequence[newSequenceResults.convo_sequence.length - 1];

    // 2. If the last value in the old convo_sequences did not have an end_time, but the new one does
    if (newLast && newLast.start_time ==  null && newLast.end_time == null && newSequenceResults.finished == false) {
        // console.log(oldLast);
        console.log('SessionState.WAIT_FOR_CONVERSATION');
        setState(SessionState.WAIT_FOR_CONVERSATION);
        return;
    }

    // 3. If the last value in the old convo_sequences had an end_time, but the new one does not
    if (newLast && !newLast.end_time) {
        // console.log(oldLast);
        console.log('SessionState.IN_CONVERSATION');
        setState(SessionState.IN_CONVERSATION);
        return;
    }


	}


  useEffect(() => {
    if (docId === null)
        return;

    // Define the function to fetch the document
    const fetchDocument = async () => {
        try {
            const docRef = doc(db, collectionName, docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const newData = docSnap.data();
                setDocumentData(newData);
            }
        } catch (error) {
            console.error("Error fetching document:", error);
        }
    };

    // Call the function immediately to get the initial data
    fetchDocument();

    // Set up an interval to fetch the document every three seconds
    const id = setInterval(fetchDocument, 3000);
    setIntervalId(id);

    // Clear the interval when the component unmounts
    return () => clearInterval(id);
  }, [collectionName, docId, db]);

  useEffect(() => {
    // If the document is different from the previous state, call onDocumentChange
    if (documentData && prevDocumentData.current && JSON.stringify(documentData) !== JSON.stringify(prevDocumentData.current)) {
        onDocumentChange(prevDocumentData.current, documentData, resetDocId);
    }
    // Update the ref with the current document data for the next comparison
    prevDocumentData.current = documentData;
  }, [documentData]);



  const resetDocId = () => {
    setDocId(null);
  }
    
  const startRecording = async () => {
    if (mediaRecorder?.state === 'recording') {
        console.log('MediaRecorder is already recording.');
        return;
    }
    
    let recorder = mediaRecorder;
    const userAgent = navigator.userAgent;
    let browserMimeType = 'audio/webm;codecs=opus';

    if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      // Use audio/mp4 for Safari
      browserMimeType = 'audio/mp4';
    }
    
    if (!recorder || recorder?.state === 'inactive') {
        try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        recorder = new MediaRecorder(stream, { mimeType: browserMimeType, audioBitsPerSecond: 128000 });
        setMediaRecorder(recorder);
        } catch (error) {
        console.error("Error starting recording: ", error);
        return;
        }
    }
    
    // Access the Deepgram API key from environment variable
    const deepgramApiKey = process.env.REACT_APP_DEEPGRAM_API_KEY;
    console.log('Deepgram Key');
    console.log(deepgramApiKey);
    const deepgram = createClient(deepgramApiKey);
    const connection = deepgram.listen.live({ model: "nova", punctuate: true });
    
    connection.on(LiveTranscriptionEvents.Open, () => {
        console.log("Connection to Deepgram opened.");
    
        recorder.ondataavailable = (e) => {
          const audioChunk = e.data;
          const reader = new FileReader();
          reader.onload = () => {
            const audioData = reader.result;
            connection.send(audioData);
          };
          reader.readAsArrayBuffer(audioChunk);
        };

        recorder.onstop = () => {
            console.log('Recording stopped');
            setIsRecording(false);
        
            // Stop the microphone stream
            if (recorder.stream) {
                recorder.stream.getTracks().forEach(track => track.stop());
            }
        };
    
        connection.on(LiveTranscriptionEvents.Transcript, (data) => {
            // Log the entire data object for debugging
            console.log("Transcript data received:", data);
        
            const transcriptSegment = data.channel?.alternatives?.[0]?.transcript || "";
            console.log(`Transcript segment: '${transcriptSegment}'`);
        
            if (transcriptSegment !== '') {
                setLastWordTimestamp(new Date());
                console.log("lastWordTimestamp updated:", lastWordTimestamp);
            }        
            // Emit transcript to the backend along with the sessionID and docId
            socket.emit('transcript', { transcript: data, sessionID: sessionID, docId: docIdRef.current });
        });
        
      });
    
      connection.on(LiveTranscriptionEvents.Close, () => {
        console.log("Connection to Deepgram closed.");
      });
    
      recorder.start(1000); // start recording
    };
      

    useEffect(() => {
        if (lastWordTimestamp) {
            console.log("lastWordTimestamp updated:", lastWordTimestamp);
        }
    }, [lastWordTimestamp]);
  
    useEffect(() => {
        console.log('useEffect is being called', isRecording, mediaRecorder?.state); 
        
        // Check if isRecording is true and docId is not null
        if (isRecording && docId !== null && (!mediaRecorder || mediaRecorder?.state !== 'recording')) {
            console.log(docId);
            startRecording();
        } else if (!isRecording && mediaRecorder?.state === 'recording') {
            mediaRecorder.stop();
        }
    }, [isRecording, mediaRecorder, docId]); // Include docId in the dependency array
      

    const record = () => {
      setIsRecording((prevIsRecording) => {
          const newRecordingState = !prevIsRecording;
      
          if (newRecordingState) {
            setDocumentData(null)
            setNumAppts(0)
            setNumConversations(0)

            console.log('Requesting to start recording with sessionID:', sessionID);
            // Emit 'record' to request starting a recording session and receive a docId
            socket.emit('record', { status: 'started' });
            setState(SessionState.WAIT_FOR_CONVERSATION);
            setShowSummary(false);
          } else {
            console.log('Stopping recording with sessionID:', sessionID, 'and doc_id:', docId);
            stopRecording(); // Function to handle stopping of recording
            setState(SessionState.NOT_RECORDING); // Update state to reflect not recording
            clearInterval(intervalId);
            setShowSummary(true);
          }
      
          return newRecordingState;
      });
    };
  
    const stopRecording = () => {
      if (mediaRecorder?.state === 'recording') {
        mediaRecorder.stop();
      }
      setState(SessionState.NOT_RECORDING);
      // Emit 'record' with status 'stopped' and the docId
      socket.emit('record', { status: 'stopped', doc_id: docId });
      // setDocId(null);
    };      

  useEffect(() => {
		// Scroll to the rightmost item
    const scrollElement = scrollRef.current;
    if (scrollElement) {
        // Calculate the position to scroll to
        const scrollPosition = scrollElement.scrollWidth - scrollElement.clientWidth;

        // Scroll to the calculated position with smooth animation
        scrollElement.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  }, [documentData]);

  const randInt0To4 = () => {
    return Math.floor(Math.random() * 5);
  }


  return (
    <div className="bg-gray-100 h-screen w-full flex flex-col justify-between"> 
      {showSummary ? (
        <SummaryView convoSequence={documentData?.convo_sequence || []} />
      ) : (
        <>
          <div className="flex justify-between p-2 sm:p-4">
            <div className="flex flex-row text-black p-1 sm:p-2 font-bold w-full">
              <div className='w-1/2'>
                <p className='font-mono'>Conversations</p>
                <p className='font-mono text-6xl'>{numConversations}</p>
              </div>
              <div className='w-1/2 text-right'>
                <p className='font-mono'>Appointments</p>
                <p className='font-mono text-6xl'>{numAppts}</p>
              </div>
            </div>

        </div>

      {/* Horizontal Scrolling Div for Houses */}
      <div className="flex-grow flex-col justify-center overflow-x-auto mx-2 sm:mx-8" ref={scrollRef}>
        <div className="flex flex-row h-full">

        {
        	state == SessionState.NOT_RECORDING ?
            (
        		<IntroView />
        	)
            :
            (
                documentData != null && documentData.convo_sequence.map((convo, index) => (
                  <HouseCard 
                    convo={convo} 
                    index={index} 
                    isMostRecent={index === documentData.convo_sequence.length - 1}
                    lastWordTimestamp={lastWordTimestamp}
                    endConversation={endConversation}
                    reward_images={reward_images}
                    animate={(index === documentData.convo_sequence.length - 1 && endingConversation)}
                    sessionState={state}
                  />
                ))
              )}
            </div>
          </div>
        </>
      )}
  
      <div className="sticky bottom-0">
        <CommandBar state={state} record={record} />
      </div>
    </div>
  );  
}



export default Motivate;