import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../Firebase.js';
import { collection, doc, getDoc } from 'firebase/firestore';
import BarLoader from "react-spinners/BarLoader";
import { sidebarSampleData, followupData, callSummaryData, householdNotes } from "../sampleData"
import Sidebar from '../Sidebar.jsx'
import { Element } from 'react-scroll'
import { FiClock, FiUser, FiXOctagon } from 'react-icons/fi'; // Import necessary icons
import RotatingText from './RotatingText.jsx'
import { FaUserAlt } from 'react-icons/fa';
import { formatNameForDisplay } from '../util.js'


function ViewResults() {
  const { docId } = useParams();
  console.log(`docID: ${docId}`)
  const [name, setName] = useState(null)
  const [note, setNote] = useState(null)
  const [answer, setAnswer] = useState(null)
  const [followup, setFollowup] = useState(null)
  // const [text, setText] = useState('')
  const [loading, setLoading] = useState(true);

  const [documentData, setDocumentData] = useState(null);
  const [keyPhrases, setKeyPhrases] = useState(null)
  const [sections, setSections] = useState([]);

  console.log(answer)
  const parseStream = (stream) => {
    // const objectionPattern = /Objection:\s*"([^"]+)".+?What the salesperson said:\s*"([^"]+)".+?Was the objection handled well:\s*(Yes|No).+?How could the objection have been handled better:\s*(.+?)(?=Objection:|$)/gs;
    const objectionSegments = stream.split('Objection:').slice(1); // Split by 'Objection:' and remove the first empty segment
    const objections = [];

    objectionSegments.forEach(segment => {
        const responseStart = segment.indexOf('What the salesperson said:');
        const ratingStart = segment.indexOf('Was the objection handled well:');
        const improvementStart = segment.indexOf('How could the objection have been handled better:');
        const transitionStart = segment.indexOf('What did the salesperson say to transition the conversation back to the script:');

        const objection = segment.slice(0, responseStart).replace(/"/g, '').trim();
        const response = segment.slice(responseStart, ratingStart).replace(/What the salesperson said:/, '').replace(/"/g, '').trim();
        const rating = segment.slice(ratingStart, improvementStart).replace(/Was the objection handled well:/, '').replace(/"/g, '').trim();
        const improvement = segment.slice(improvementStart, transitionStart).replace(/How could the objection have been handled better:/, '').replace(/"/g, '').trim();
        const transition = segment.slice(transitionStart).replace(/What did the salesperson say to transition the conversation back to the script:/, '').replace(/"/g, '').trim();

        objections.push({ objection: objection, response: response, rating: rating, improvement: improvement, transition: transition });

    });
    return {
        objections: objections
    };
}


  useEffect(() => {
    const checkDocument = async () => {

      console.log('Checking for document...')
      const docRef = doc(collection(db, "sales_conversations"), docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        console.log('Document exists.')
        setDocumentData(data => docSnapshot.data());
        console.log(docSnapshot.data().objections_message)
        console.log(docSnapshot.data().followup_message)
        console.log(`bandwagon lines: ${docSnapshot.data().bandwagon_lines}`)
        console.log(`urgency lines: ${docSnapshot.data().urgency_lines}`)
        const keyPhrasesObject = {
          bandwagon_lines: docSnapshot.data().bandwagon_lines,
          urgency_lines: docSnapshot.data().urgency_lines
        }
        const objections = parseStream(docSnapshot.data().objections_message)
        setName(name => docSnapshot.data().name)
        setNote(note => docSnapshot.data().note)
        setKeyPhrases(keyPhrases => keyPhrasesObject)
        setAnswer(answer => objections)
        setFollowup(followup => docSnapshot.data().followup_message.substring(8))
        setLoading(loading => false)
        clearInterval(interval); // Clear the interval once document is found
        console.log(`objections (setting sidebar): ${objections.length}`)
        
        let sideBarSections = []
        sideBarSections.push({id: 'callSummary', title: 'Call Summary'})
        for(let i = 0; i < objections['objections'].length; i++){
          sideBarSections.push({id: `objection${i}`, 'title':`Objection ${i+1}`})
        }
        sideBarSections.push({id: 'followup', title: 'Customer Follow-up'})
        sideBarSections.push({id: 'keyPhrases', title: 'Tactics'})
        setSections(sideBarSections)
      } else {
        console.log('Document doesnt exist.')
      }
    };
    
    checkDocument();
    const interval = setInterval(checkDocument, 5000);
    return () => clearInterval(interval); // Cleanup
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen min-w-screen pt-8"> {/* Added pt-8 for top padding */}
      <div className="w-full h-14 bg-white flex items-center justify-between px-4 border-b border-gray-200 fixed top-0 z-50">
          <span className="text-blue-900 text-lg font-bold">Fielder</span>
          <div className="flex items-center">
              <div className="flex items-center  hover:bg-gray-200 p-1 m-1 rounded cursor-pointer">
                  
                  <FaUserAlt className="text-2xl m-2 mx-4 text-gray-800" />
                  <div className='flex flex-col mr-2'>
                  <span className="text-gray-800 text-md font-bold">{formatNameForDisplay(name)}</span>
                  <span className="text-gray-600 text-xs">{''}</span>
                  </div>
              </div>
          </div>
      </div>
      
      <div className='flex flex-row w-full h-full pt-12'> {/* Padding top for the header */}
        {/* Sidebar */}
        {
          !loading &&
          <div className='fixed top-12 left-0 h-full'> {/* Fixed position for the sidebar */}
              <Sidebar sections={sections} title={'Conversation Contents'} />
          </div>
        }
        <div className='flex flex-row w-full h-full justify-center'>
          <div className='flex-column max-w-2xl w-full h-full px-2'> 
            {
              !loading &&
              <div className='mb-8'>
                <div>
                <p className='text-gray-500 text-sm'>{name ? formatNameForDisplay(name) : '[No name provided]'}: {note ? note : '[No note provided]'}</p>
                </div>
                <h1 className="text-xl font-bold mb-4 text-gray-700">Conversation Summary</h1> 
                <Element key={'callSummary'} id={'callSummary'} name={'callSummary'}>
                <CallSummaryCard
                  id={'callSummary'} 
                  data={callSummaryData} 
                  speakerPercentages={documentData.speaker_percentages}
                  duration={documentData.duration_minutes}
                  objectionsCount={documentData.objections_count}
                />
                </Element>
              </div>
            }
            { 
              answer != null &&
              <div className='px-4 md:px-0 md:mx-auto w-full'>
                <div> 
                  <h1 className="text-xl font-bold mb-4 text-gray-700">Objections</h1> 
                  {
                    answer['objections'].length == 0 ?
                    <p className='w-full text-center text-gray-700 bg-gray-100 rounded p-4 my-4'>No objections found</p>
                    : null
                  }
                  {
                    answer['objections'].length  > 0 ? 
                    answer['objections'].map((objectionItem, idx) => (
                      <Element key={`objection${idx}`} id={`objection${idx}`} name={`objection${idx}`}>
                      <ObjectionCard
                        id={`objection${idx}`}  
                        key={idx} 
                        {...objectionItem} 
                        clipUrl={documentData.clip_urls[idx]} // Add this line
                      />
                      </Element>
                    )) 
                    : null
                  }
                </div>
                <div className='mb-8'> 
                  <h1 className="text-xl font-bold mb-4 text-gray-700">Customer Follow-up</h1> 
                  <FollowupCard id={'followup'} message={followup} />
                </div>
                <div>
                  <h1 className="text-xl font-bold mb-4 text-gray-700">Tactics</h1>
                    <KeyPhrasesCard
                      id={keyPhrases}
                      keyPhrases={keyPhrases}
                    />
                </div>

              </div>
            }
            {
              loading && 
              <div className='flex flex-col justify-center items-center'>
                <BarLoader
                  color={'#000000'}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <RotatingText />

              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );  


}

const TranscriptLine = ({ text }) => {
    const parts = text.split(/<F>(.*?)<\/F>/).map((part, idx) => {
        if (idx % 2 === 1) {
            return <span key={idx} className="bg-yellow-200 rounded px-1">{part}</span>;
        }
        return part;
    });

    return (
        <div className="p-4 border-b">
            {parts}
        </div>
    );
}


const ObjectionCard = ({ id, objection, response, rating, improvement, transition, clipUrl }) => (  
  <div id={id} className="border rounded p-6 shadow-sm my-4 w-full">
    <h2 className="text-xs text-blue-700">Objection</h2>
    <p className="text-gray-700 mb-4">{objection}</p>
    
    <div className='flex flex-row'>
    <h2 className="text-xs text-blue-700">Rebuttal</h2>
    {
      rating == 'Yes' ?
      <p className="font-semibold text-xs text-green-700 bg-green-200 mx-1 px-1 rounded">Strong</p>
      :
      <p className="font-semibold text-xs text-red-700 bg-red-200 mx-1 px-1 rounded">Weak</p>

    }
    </div>
    <p className="text-gray-700 mb-4">{response}</p>

    <h2 className="text-xs text-blue-700">Feedback</h2>
    <p className="text-gray-700 mb-4">{improvement}</p>
{/*
    <h2 className="font-semibold text-sm">Transition:</h2>
    <p className="text-gray-700 mb-4">{transition}</p>
*/}    
    <h2 className="text-xs text-blue-700">Audio</h2>
    <div className="audio-player">
        <audio controls className="h-10 rounded">
            <source src={clipUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </div>
  </div>
);

const FollowupCard = ({ id, message }) => {
  console.log(`message: ${message}`)
  const messageRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (messageRef.current) {
      const textToCopy = messageRef.current.textContent;
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset after 2 seconds
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  };

  return (
    <Element key={id} id={id} name={id}>
    <div id={id} className="border rounded p-6 shadow my-4 w-full relative whitespace-pre-line">
      <button onClick={handleCopy} className="absolute top-2 right-2 bg-gray-300 text-gray-800 rounded p-1 text-sm hover:bg-blue-600 hover:text-white">
        {copied ? 'Copied!' : 'Copy'}
      </button>
      <h2 className="text-xs text-blue-700">Message</h2>
      <p ref={messageRef} className="text-gray-700 mb-4">{message}</p>
    </div>
    </Element>
  );
};

const KeyPhrasesCard = ({id, keyPhrases }) => {
  return (
    keyPhrases.bandwagon_lines.length == 0
    ?
    <p className='w-full text-center text-gray-700 bg-gray-100 rounded p-4 my-4'>No tactics found</p>
    :
    <div id={id} className="border rounded p-6 shadow my-4 w-full">
      <Element key={`keyPhrases`} id={`keyPhrases`} name={`keyPhrases`}>
      <h2 className="text-xs text-blue-700">Bandwagon / Neighbor</h2>
      <div>
      {
        keyPhrases.bandwagon_lines.map((line, index) => (<p className="text-gray-700 mb-4" key={index}>• {line}</p>))
      }
      </div>    
      {/*<h2 className="font-semibold text-sm">Urgency Lines</h2>
      <div>
      {
        keyPhrases.urgency_lines.map((line, index) => (<p className="text-gray-700 mb-4" key={index}>• {line}</p>))
      }
      </div>*/}
      </Element>     
    </div>
  )
};




const CallSummaryCard = ({ id, data, speakerPercentages, duration, objectionsCount }) => {
  console.log(speakerPercentages)
  console.log(`speaker_percentages: ${speakerPercentages.salesperson}`)
  const minutes = Math.floor(duration);
  const seconds = Math.round((duration - minutes) * 60);

  // Convert the map to an array of [speaker, percentage] and sort it
  const sortedSpeakerPercentages = Object.entries(speakerPercentages)
    .sort(([, percentageA], [, percentageB]) => percentageB - percentageA);
  console.log(`sortedSpeakerPercentages: ${sortedSpeakerPercentages}`)
  return (
    <div id={id} className="flex border rounded p-4 shadow my-4 w-full">
      {/* Length Column */}
      <div className="flex-1">
        <div className="flex justify-between items-center">
          <h2 className="font-semibold text-sm text-blue-700">Length</h2>
          <FiClock className="text-gray-700" size={24} />
        </div>
        <p className="text-gray-700">{minutes}m {seconds}s</p>
      </div>

      <div className="mx-4 border-r border-gray-400"></div> {/* Vertical Divider */}

      {/* Speakers Column */}
<div className="flex-1">
  <div className="flex justify-between items-center">
    <h2 className="font-semibold text-sm text-blue-700">Speakers</h2>
    <FiUser className="text-gray-700" size={24} />
  </div>
  <div>
    {sortedSpeakerPercentages.map(([speaker, percentage], index) => (
      <div key={index} className="mb-2">
        <span className="text-gray-600 text-xs capitalize">{speaker}</span>
        <div className="flex items-center mt-1">
          <div className="bg-blue-500 h-2 rounded" style={{ width: `${percentage}%` }}></div>
          <span className="text-gray-600 text-xs ml-2">{Math.round(percentage)}%</span>
        </div>
      </div>
    ))}
  </div>
</div>

      <div className="mx-4 border-r border-gray-400"></div> {/* Vertical Divider */}

      {/* Objections Column */}
      <div className="flex-1">
        <div className="flex justify-between items-center">
          <h2 className="font-semibold text-sm text-blue-700">Objections</h2>
          <FiXOctagon className="text-gray-700" size={24} />
        </div>
        <p className="text-gray-700">{objectionsCount}</p>
      </div>
    </div>
  );
}







export default ViewResults;