import React from 'react';

function SummaryView({ convoSequence }) {
    const reward_images = {
        0: ['GIF', '/motivational_gifs/belfort.gif'],
        1: ['GIF', '/motivational_gifs/bibles.gif'],
        2: ['GIF', '/motivational_gifs/taylor.gif'],
        3: ['GIF', '/motivational_gifs/you_got_this.gif'],
        4: ['1 can of Red Bull', '/reward_images/red_bull.png'],
        5: ['$20 Amazon Gift Card', '/reward_images/amazon_card.png'],
        6: ['Red Lobster Dinner for 2', '/reward_images/lobster.png'],
        7: ['X-Box Controller', '/reward_images/xbox_controller.png'],
        8: ['Callaway Golf Bag', '/reward_images/golf_bag.png'],
    };

    // Calculate rewards based on convoSequence
    const rewardsAccrued = convoSequence
        .filter(convo => convo.prize_status === 'achieved')
        .map(convo => ({
            tier: convo.reward_tier,
            imageUrl: reward_images[convo.reward_tier][1],
            title: reward_images[convo.reward_tier][0]
        }));

    // Calculate number of conversations and appointments
    const numConversations = convoSequence.filter(convo => convo.start_time != null).length;
    const numAppts = convoSequence.filter(convo => convo.outcome === 'appointment_set').length;

    return (
        <div>
            <div className="flex justify-between p-2 sm:p-4">
                <div className="flex flex-row text-black p-1 sm:p-2 font-bold w-full">
                    <div className='w-1/2'>
                    <p className='font-mono'>Conversations</p>
                    <p className='font-mono text-6xl'>{numConversations}</p>
                    </div>
                    <div className='w-1/2 text-right'>
                    <p className='font-mono'>Appointments</p>
                    <p className='font-mono text-6xl'>{numAppts}</p>
                    </div>
                </div>
            </div> 
            <div className='flex flex-col items-center'>
                {rewardsAccrued.length > 0 ? (
                    <div className='flex flex-col items-center m-4'>
                        <div className='flex flex-col items-center m-4'>
                            <p className='text-2xl text-gray-800 font-bold italic p-2 mt-2 bg-yellow-300 rounded-lg'>You crushed it!</p>
                        </div>
                        <p className='text-3xl text-gray-800 font-bold p-2 rounded'>Rewards Earned</p>
                        <div className="flex justify-center items-center flex-wrap">
                            {rewardsAccrued.map((reward, index) => (
                                <img key={index} className="m-2 w-24 h-24" src={reward.imageUrl} alt={`Reward ${reward.tier}`} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col  items-center m-4'>
                        <div className='flex flex-col items-center m-4'>
                            <p className='text-2xl text-gray-800 font-bold italic p-2 mt-2 bg-yellow-300 rounded-lg'>Get back out and keep knocking!</p>
                        </div>
                        <p className='text-3xl text-gray-800 font-bold p-2 rounded'>No rewards earned</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SummaryView;

  