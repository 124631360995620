import React, { useEffect, useState, useRef } from 'react';


const DaySummaryCard = ({dailyUserReports}) => {
  const repSummaries = Object.keys(dailyUserReports).map(key => dailyUserReports[key])

  console.log("DaySummaryCard: ", repSummaries)
  console.log("DaySummaryCard: ", repSummaries[0])
  
  function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  // UPDATE THE BODY OF THIS COMPONENT
  return (
    <div className="border rounded p-6 my-4 w-full shadow-lg">
      <div className="mb-4">
        <h3 className="text-xs text-blue-700">Summary</h3>
        <ul className="list-disc list-inside text-gray-700">
          {repSummaries.map((r) => (
            <div className='my-2'>
              <div className='justify-between'>
                <div>
                  <h2 className="text-lg font-bold text-gray-900">{r.name}</h2>
                </div>
                <div className="text-left">
                  <h2 className="text-gray-900 text-xs mb-2">{r.num_conversations} conversations - {r.num_appointments_set} appointments</h2>
                </div>
              </div>
  
              {/* Challenges Section */}
              <div className="flex flex-col py-1">
                <div className='text-xs py-1'><p>Challenges: </p></div>
                <div className="flex flex-wrap gap-2">
                  {
                    r.top_issues.map((issue, index) => (
                      <div key={index} className="bg-red-100 text-red-700 rounded px-2 py-1 max-w-xs text-xs">
                        {truncateText(issue.explanation, 50)}
                      </div>
                    ))
                  }
                </div>
              </div>
  
              {/* Highlights Section */}
              <div className="flex flex-col py-1">
                <div className='text-xs py-1'><p>Highlights: </p></div>
                <div className="flex flex-wrap gap-2">
                  {
                    r.top_positives.map((positive, index) => (
                      <div key={index} className="bg-green-100 text-green-700 rounded px-2 py-1 max-w-xs text-xs">
                        {truncateText(positive.explanation, 35)}
                      </div>
                    ))
                  }
                </div>
              </div>
  
            </div>
          ))}
        </ul>
      </div>
    </div>
  );  
}



export default DaySummaryCard