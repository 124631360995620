import React, { useState } from 'react';

function FileUpload({file, setFile }) {
  const [filename, setFilename] = useState('');

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setFilename(file.name);
  }

  // Use the variant if it's provided, otherwise use a default message
  const uploadLabel = 'Upload a file';

  return (
    <div className='flex flex-col m-6'>
      <label className='text-sm mb-2'>{uploadLabel}</label>
      <div className="relative mb-1">
        <input 
          type="file"
          accept="*"
          onChange={handleUpload}
          className='absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer'
        />
        <div className="text-center py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700 transition duration-300">
          Choose a file
        </div>
      </div>
      {filename && <p className="text-gray-700">Selected: {filename}</p>}
    </div>
  );
}

export default FileUpload;