import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { queryDailyUserReports } from '../Firebase.js';
import RepSummaryCard from './RepSummaryCard.jsx'
import DaySummaryCard from './DaySummaryCard.jsx'
// import { MdMic } from 'react-icons/md';
import Sidebar from '../Sidebar.jsx'
import { Element } from 'react-scroll'



const DayReview = () => {
  const formatDateToString = (date) => {
    return new Date(date).toLocaleDateString('en-CA', { // 'en-CA' format is YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const [teamName, setTeamName] = useState("teamDawgs"); // Assuming this is set elsewhere
  const [todaysDate, setTodaysDate] = useState(formatDateToString(new Date()));
  const [dailyUserReports, setDailyUserReports] = useState(null);
  const [sections, setSections] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("useEffect triggered with teamName:", teamName, "and todaysDate:", todaysDate);

    async function fetchData() {
      console.log("fetchData started");
      try {
        console.log("Calling queryDailyUserReports...");
        const reports = await queryDailyUserReports(teamName, '2023-12-07');
        console.log("Data received from queryDailyUserReports:", reports);

        if (!reports) {
          console.log("No reports found. Received:", reports);
        } else {
          console.log("Setting daily user reports with data:", reports);
          setDailyUserReports(reports);
          let sideBarSections = []
          sideBarSections.push({id: 'summary', title: 'Team Summary'})
          for(let i = 0; i < reports.length; i++){
            sideBarSections.push({id: reports[i].name, 'title':reports[i].name})
          }
          setSections(sideBarSections)
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        // Consider additional error handling or state updates here
      }
    };

    fetchData();
  }, []); // Dependencies array
  useEffect(() => {
    console.log("WTF")
  }, [])


  function renderRepSummaryCards() {
    return (
        dailyUserReports.map((report) => (
            <Element key={report.name} id={report.name} name={report.name} className="w-full">
                <RepSummaryCard
                    key={report.name}
                    name={report.name}
                    numAppointmentsSet={report.num_appointments_set}
                    numConversations={report.num_conversations}
                    topIssues={report.top_issues}
                    topPositives={report.top_positives}
                    className="w-full" // Ensures full width on small screens
                />
            </Element>
        ))
    );
  }


  
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }
  


  // const formattedLastUpdate = lastUpdated.toLocaleString();

  return (
    <div className="flex flex-col items-center min-h-screen min-w-screen pt-8"> {/* Added pt-8 for top padding */}
      <div className="w-full h-14 bg-white flex items-center justify-between px-4 border-b border-gray-200 fixed top-0">
          <span className="text-blue-900 text-lg font-bold">Fielder</span>
          <div className="flex items-center">
              <div className="flex items-center  hover:bg-gray-200 p-1 m-1 rounded cursor-pointer">
                  <img src="/fitz.jpeg" alt="Profile" className="h-8 w-8 rounded-full mr-2"/>
                  <div className='flex flex-col mr-2'>
                  <span className="text-gray-800 text-md font-bold">{'Fitzgerald'}</span>
                  <span className="text-gray-600 text-xs">Organic Solar</span>
                  </div>
              </div>
          </div>
      </div>
      
      <div className='flex flex-row w-full h-full pt-12'> {/* Padding top for the header */}
        {
          dailyUserReports !== null &&
          <div className='fixed top-12 left-0 h-full'> {/* Fixed position for the sidebar */}
              <Sidebar sections={sections} title={'Contents'}/>
          </div>
        }
        <div className='flex flex-row w-full h-full justify-center'>
          <div className='flex-column max-w-2xl w-full h-full px-2'> 
            {
              <div className='mb-8'>

                <h1 className="text-xl font-bold text-gray-700">Daily Summary</h1> 
                <div><p className='text-gray-500 text-xs mb-4'>{`${formatDate(todaysDate)}`}</p></div>
                {
                  dailyUserReports === null ? (
                    <p>Loading...</p>
                  ) : (
                    <Element key={'reportSummary'} id={'reportSummary'} name={'reportSummary'}>
                      <DaySummaryCard dailyUserReports={dailyUserReports}/>
                    </Element>
                  )
                }
              </div>
            }  
            { 
              <div className='md:px-0 md:mx-auto w-full'>
                <div> 
                  <h1 className="text-xl font-bold text-gray-700">Detailed Analysis</h1> 
                  <div><p className='text-gray-500 text-xs'>{`${formatDate(todaysDate)}`}</p></div>

                  {
                    <div className="flex flex-col items-center w-full min-h-screen">
                      {dailyUserReports === null ? (
                        <p>Loading...</p>
                      ) : renderRepSummaryCards()
                      }
                    </div>
                  }
                </div>


              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );  
    


}

export default DayReview;












