import React from 'react';

const SessionState = Object.freeze({
  NOT_RECORDING: 'NOT_RECORDING',
  NO_CONVERSATIONS: 'NO_CONVERSATIONS',
  WAIT_FOR_CONVERSATION: 'WAIT_FOR_CONVERSATION',
  IN_CONVERSATION: 'IN_CONVERSATION',
});

const CommandBar = ({ state, record }) => {
  return (
    <div className="flex justify-center items-center m-2 h-16">
      {state === SessionState.NOT_RECORDING ? (
        <button onClick={record} className="text-2xl p-4 bg-green-500 text-white rounded-md h-16 flex-1">Start</button>
      ) : (
        <button onClick={record} className="text-2xl p-4 bg-gray-300 text-gray-700 rounded h-16 flex-1">Finish</button>
      )}
      
    </div>
  );
};


export default CommandBar;
