import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { queryRepConversations, queryTeamConversations } from '../Firebase.js';
import { parseObjections, formatNameForDisplay } from '../util.js'
import { useNavigate, useParams } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';




// import Modal from './Modal'; // Assuming Modal is a separate component
function formatTimestampInSeconds(timestampInSeconds) {
  const date = new Date(timestampInSeconds * 1000); // Convert to milliseconds

  // Format date as YYYY-MM-DD
  const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');

  // Format time as HH:MM AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + ampm;

  return formattedDate + ' ' + formattedTime;
}

function getTimeFromTimestamp(timestampInSeconds) {
  const date = new Date(timestampInSeconds * 1000); // Convert to milliseconds

  // Format time as HH:MM AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + ampm;

  return formattedTime;
}


function getDateFromTimestamp(timestampInSeconds) {
  const date = new Date(timestampInSeconds * 1000); // Convert to milliseconds

  // Format date as MM/DD/YY
  const formattedDate = (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
                        date.getDate().toString().padStart(2, '0') + '/' +
                        date.getFullYear().toString().substr(-2);

  return formattedDate;
}



// Define a default UI for filter
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;
  
  return (
    <input
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  );
};

const RepView = () => {
  // const [repName, setRepName] = useState("Kado"); // Assuming this is set elsewhere
  const [repConversations, setRepConversations] = useState([]);
  const [currentNote, setCurrentNote] = useState('');
  const [expandedRows, setExpandedRows] = useState({});

  const navigate = useNavigate(); // Initialize useNavigate
  let { teamName, repName } = useParams();
  if (repName) repName = repName.toLowerCase()
  if (teamName) teamName = teamName.toLowerCase()
  console.log(`teamName: ${teamName}`)
  console.log(`repName: ${repName}`)



  console.log(repConversations)
  useEffect(() => {
    console.log("repname: ")
    console.log(repName)
    if (repName === null || repName === undefined){
      console.log("getting full team conversations...")
      const fetchData = async () => {
        try {
          const conversations = await queryTeamConversations(teamName);
          setRepConversations(conversations);
          console.log("Conversations fetched:", conversations);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle the error appropriately
        }
      };
      fetchData();
    }
    else {    
      console.log("getting full team conversations...")
      const fetchData = async () => {
        try {
          const conversations = await queryRepConversations(repName);
          setRepConversations(conversations);
          console.log("Conversations fetched:", conversations);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle the error appropriately
        }
      };

      fetchData();
    }
  }, [repName, teamName]);

  // const handleFollowUpClick = (note) => {
  //   setCurrentNote(note);
  //   setIsModalOpen(true);
  // };

  const toggleExpandRow = (rowId) => {
    // setExpandedRows(prevExpandedRows => ({
    //   ...prevExpandedRows,
    //   [rowId]: !prevExpandedRows[rowId],
    // }));
  };
  function objectionsFilter(rows, id, filterValue) {
    return rows.filter(row => {
      // Get the objections for the row
      const objections = row.values[id];

      // Check if any objection text includes the filter value
      return objections.some(objection => objection.objection.toLowerCase().includes(filterValue.toLowerCase()));
    });
  }

  // Define columns for react-table
  const columns = useMemo(
    () => [
      {
        Header: 'Rep',
        accessor: d => formatNameForDisplay(d.name),
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Note',
        accessor: 'note',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Date',
        accessor: 'timestamp.seconds',
        Cell: ({ value }) => getDateFromTimestamp(value),
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Time',
        accessor: d => getTimeFromTimestamp(d.timestamp.seconds),
        Filter: DefaultColumnFilter,
      },      
      {
        Header: 'Length',
        accessor: d => `${Math.round(d.duration_minutes)}m`,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Objections',
        accessor: d => parseObjections(d.objections_message),
        Cell: ({ value }) => (
          <div>
            {value.slice(0, 5).map((objection, index) => (
              <span key={index} className="inline-flex items-center justify-center px-2 py-1 my-1 mr-2 text-sm leading-none text-gray-800 bg-gray-200 rounded">
                {objection.objection.length > 70 ? `${objection.objection.substring(0, 67)}...` : objection.objection}
              </span>
            ))}
          </div>
        ),
        Filter: ({ column: { filterValue, setFilter, id } }) => (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
            placeholder={`Search objections...`}
          />
        ),
        filter: objectionsFilter,
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { columns, data: repConversations, initialState: { pageIndex: 0 } },
    useFilters,
    useSortBy,
  );

  if (repConversations === null)
    return <p>Loading...</p>
  // Fetch data for repConversations
  // ...


  return (
    // Component JSX
    <div className="flex flex-col items-center min-h-screen min-w-screen pt-8"> {/* Added pt-8 for top padding */}
      <div className="w-full h-14 bg-white flex items-center justify-between px-4 border-b border-gray-200 fixed top-0 z-50">
          <span className="text-blue-900 text-lg font-bold">Fielder</span>
          <div className="flex items-center">
              <div className="flex items-center  hover:bg-gray-200 p-1 m-1 rounded cursor-pointer">
                  
                  <FaUserAlt className="text-2xl m-2 mx-4 text-gray-800" />
                  <div className='flex flex-col mr-2'>
                  <span className="text-gray-800 text-md font-bold">{formatNameForDisplay(repName)}</span>
                  <span className="text-gray-600 text-xs">{teamName}</span>
                  </div>
              </div>
          </div>
      </div>
      <div className="flex flex-col items-center min-h-screen min-w-screen pt-8 z-10">
        {/* Rest of the component */}
        <div className="w-full px-12">
          <h2 className="text-2xl font-bold mb-5 text-gray-700 text-left">Conversations</h2>
          <div className="flex flex-col items-center">
            <div className="border rounded shadow-md my-4 w-full">
              <table {...getTableProps()} className="w-full text-gray-700">
              <thead className="bg-gray-100">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th 
                        {...column.getHeaderProps(column.getSortByToggleProps())} 
                        className="py-3 px-2 text-left text-xs text-blue-500 font-normal border-b border-gray-200 relative">
                        <div className="flex items-center justify-between">
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </div>
                        <div className="mt-2">
                          {column.canFilter ? (
                            <input
                              type="text"
                              className="mt-1 block w-full rounded border border-gray-300 p-1"
                              value={column.filterValue || ''}
                              onChange={e => column.setFilter(e.target.value || undefined)}
                              placeholder={`Search...`}
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={i}>
                        <tr {...row.getRowProps()} onClick={() => window.open(`/result/${repConversations[i].docId}`, '_blank')} className="cursor-pointer hover:bg-gray-100">
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="border-b py-2 text-sm px-3">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                        {expandedRows[i] && (
                          <tr>
                            <td colSpan={columns.length}>
                              <div className="p-4 bg-gray-100">
                                
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

        // <Modal note={currentNote} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
// const Modal = ({ note, isOpen, onClose }) => {
// 	const noteRef = useRef(null);

// 	const copyToClipboard = () => {
// 		const range = document.createRange();
// 		range.selectNode(noteRef.current);
// 		window.getSelection().removeAllRanges();
// 		window.getSelection().addRange(range);
// 		document.execCommand('copy');
// 		window.getSelection().removeAllRanges();
// 	};

// 	if (!isOpen) return null;

// 	return (
// 		<div className="fixed z-50 inset-0 overflow-y-auto">
// 			<div className="flex items-center justify-center min-h-screen">
// 				<div className="bg-white p-4 rounded-lg shadow-md w-1/3">
// 					<h2 className="text-xl font-bold mb-4">Follow-up Note</h2>
// 					<p ref={noteRef}>{note}</p>
// 					<div className="mt-4 flex justify-end">
// 						<button className="bg-blue-500 text-white px-4 py-2 rounded mr-2" onClick={copyToClipboard}>Copy</button>
// 						<button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={onClose}>Close</button>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

export default RepView;
