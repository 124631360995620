import React from 'react';
import { Link, Element } from 'react-scroll'; // react-scroll for smooth scrolling

const Sidebar = ({ sections, title }) => {
    console.log(sections) 
    if(!sections)
        return null
    return (
        <div className="h-full hidden lg:block pt-4"> {/* Hidden on small screens */}
            <div className='pl-4'>
            <p className="text-xs font-bold text-gray-500">
                {title}
            </p>
            </div>
            <ul className="flex flex-col pl-4">
                {sections.map(section => (
                    <li key={section.id}>
                        <Link
                            to={section.id}
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-60} // Offset for the top bar. Tailwind is 12 pixels per unit. pt-12 --> 48 px + 12 for margin
                            activeClass="bg-gray-200 font-bold"
                            className="transition-colors text-xs text-gray-500 duration-300 hover:text-blue-500 cursor-pointer p-2 rounded"
                        >
                            {section.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
