import React, { useState } from 'react';

const Transcript = ({ transcript }) => {
  return (
    <ul className="list-none pl-4 py-1">
      {transcript.map((t, index) => (
        <li key={index} className="text-sm text-gray-600 bg-gray-100 p-2 rounded">
          <span className="font-semibold">{t.speaker[0].toUpperCase() + t.speaker.substring(1)}:</span> {t.line}
        </li>
      ))}
    </ul>
  );
};

const RepSummaryCard = ({ name, numAppointmentsSet, numConversations, topIssues, topPositives }) => {
  const [showIssueTranscriptIndex, setShowIssueTranscriptIndex] = useState(null);
  const [showPositiveTranscriptIndex, setShowPositiveTranscriptIndex] = useState(null);

  const toggleIssueTranscript = (index) => {
    setShowIssueTranscriptIndex(showIssueTranscriptIndex === index ? null : index);
  };

  const togglePositiveTranscript = (index) => {
    setShowPositiveTranscriptIndex(showPositiveTranscriptIndex === index ? null : index);
  };

  return (
    <div className="border rounded-lg p-6 shadow-lg my-4 w-full bg-white">
      <h2 className="text-xl font-bold text-gray-900">{name}</h2>
      <div className="text-left ">
        <h2 className="text-gray-900 text-xs mb-2">{numConversations} conversations - {numAppointmentsSet} appointments</h2>
      </div>
      <div className="mb-4">
        <h3 className="text-xs text-gray-700">Challenges</h3>
        {topIssues.map((issue, index) => (
          <div key={index} className="mb-2">
            <p className="bg-red-100 text-red-700 text-sm cursor-pointer rounded p-1" onClick={() => toggleIssueTranscript(index)}>
              {issue.explanation}
            </p>
            {showIssueTranscriptIndex === index && <Transcript transcript={issue.transcript} />}
          </div>
        ))}
      </div>

      <div className="mb-4">
        <h3 className="text-xs text-gray-700">Highlights</h3>
        {topPositives.map((positive, index) => (
          <div key={index} className="mb-2">
            <p className="bg-green-100 text-green-700 text-sm cursor-pointer rounded p-1" onClick={() => togglePositiveTranscript(index)}>
              {positive.explanation}
            </p>
            {showPositiveTranscriptIndex === index && <Transcript transcript={positive.transcript} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepSummaryCard;
