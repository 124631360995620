import { v4 as uuidv4 } from 'uuid'; // for generating a unique ID for each file
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDocs, getDoc, serverTimestamp, where, query } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.FIRE_BASE_API_KEY,
  authDomain: "fastab-f08e9.firebaseapp.com",
  projectId: "fastab-f08e9",
  storageBucket: "fastab-f08e9.appspot.com",
  messagingSenderId: "439667307825",
  appId: "1:439667307825:web:1e0a4cda7ac3bda5376c10",
  measurementId: "G-31TL7C8J18"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage();
const db = getFirestore(app);

const storeFile = (file, fileName) => {
  return new Promise((resolve, reject) => {
    const filePath = `audios/${fileName || uuidv4()}`;
    const storageRef = ref(storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
      }, 
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed', error);
        reject(error);
      }, 
      async () => {
        // Do something once upload is complete
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);
          resolve(downloadURL);
        } catch (error) {
          console.error('Failed to get download URL', error);
          reject(error);
        }
      }
    );
  });
};

// Function to save email to Firebase
async function saveEmailToFirebase(email) {
  try {
    const docRef = doc(collection(db, "fielder-demo-booking"));
    await setDoc(docRef, {
      email: email,
      timestamp: serverTimestamp() // This is how you call serverTimestamp in modular SDK
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function queryDailyUserReports(teamName, todaysDate) {
  console.log("queryDailyUserReports called with teamName:", teamName, "todaysDate:", todaysDate);
  
  try {
    const queryRef = query(
      collection(db, "daily-user-report"), 
      where("team", "==", teamName),
      where("date", "==", todaysDate)
    );

    console.log("Executing Firebase query:", queryRef);
    const querySnapshot = await getDocs(queryRef);
    console.log("Query snapshot received:", querySnapshot);

    let reports = [];
    querySnapshot.forEach((doc) => {
      console.log("Document data:", doc.data());
      reports.push(doc.data());
    });

    console.log("Final reports array:", reports);
    return reports;
  } catch (e) {
    console.error("Error in queryDailyUserReports:", e);
    throw e; // Re-throw the error to be caught in the React component
  }
}

// Function to query documents from Firebase
async function queryRepConversations(repName) {
  try {
    const q = query(collection(db, "sales_conversations"), 
                    where("name", "==", repName));
    const querySnapshot = await getDocs(q);
    const conversations = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // Add the document ID as an attribute of each object
      conversations.push({ ...data, docId: doc.id });
    });
    return conversations; // Return the updated array
  } catch (e) {
    console.error("Error retrieving documents: ", e);
    return null;
  }
}

// Function to query team conversations from Firebase
async function queryTeamConversations(teamId) {
  try {
    // Query the team members from the fielder-teams collection
    const teamQuery = query(collection(db, "fielder-teams"), where("teamName", "==", teamId));
    const teamSnapshot = await getDocs(teamQuery);
    
    // Assuming there's only one team with the given teamId
    if (!teamSnapshot.empty) {
      const teamData = teamSnapshot.docs[0].data();
      const teamMembers = teamData.teamMembers;

      // Array to store all conversations
      const allConversations = [];

      // Iterate through team members and query their conversations
      for (const member of teamMembers) {
        const memberConversationsQuery = query(collection(db, "sales_conversations"), where("name", "==", member));
        const memberConversationsSnapshot = await getDocs(memberConversationsQuery);

        // Add each conversation to the array
        memberConversationsSnapshot.forEach((doc) => {
          const data = doc.data();
          allConversations.push({ ...data, docId: doc.id });
        });
      }

      return allConversations;
    } else {
      console.error("No team found with the provided teamId");
      return null;
    }
  } catch (e) {
    console.error("Error retrieving documents: ", e);
    return null;
  }
}


async function fetchConversationsForUser(userName) {
  try {
    const conversationSequencesRef = collection(db, 'conversation_sequences');
    const q = query(conversationSequencesRef, where("user", "==", userName));
    const querySnapshot = await getDocs(q);
    
    const conversations = [];
    querySnapshot.forEach(doc => {
      // Each doc is a conversation, we add it to the array
      conversations.push({ id: doc.id, ...doc.data() });
    });

    return conversations;
  } catch (error) {
    console.error("Error fetching conversations: ", error);
    return []; // Return an empty array in case of error
  }
}




export {
  db,
  storeFile,
  saveEmailToFirebase,
  queryDailyUserReports,
  queryRepConversations,
  queryTeamConversations,
  fetchConversationsForUser
}
