const followupData = {
  message:
`Hi...
Thanks for...
Talk soon,...
`
}

const callSummaryData = {
  numObjections: 4,
  numObjectionsOvercome: 3,
  callLengthMins: 10
}

const householdNotes = {
  notes:
`
- Work from home tuesday and thursday
- Dog named sparky
- Going on vacation to Hawaii
- Concerned about blackouts
- Concerned about drilling holes in tile / waterproofing
`
}


const sidebarSampleData = {
  "2023-10-05": [
    {
      name: "John",
      type: "Summary",
      totalCalls: 10,
      overallScore: 85,
      handledWellPercentage: 70
    },
    {
      name: "John",
      type: "Call",
      length: "15 minutes",
      objections: 5,
      handledWellPercentage: 80
    },
    {
      name: "John",
      type: "Call",
      length: "10 minutes",
      objections: 2,
      handledWellPercentage: 50
    },
    {
      name: "Mike",
      type: "Summary",
      totalCalls: 8,
      overallScore: 75,
      handledWellPercentage: 60
    },
    {
      name: "Mike",
      type: "Call",
      length: "20 minutes",
      objections: 4,
      handledWellPercentage: 40
    },
    {
      name: "Mike",
      type: "Call",
      length: "10 minutes",
      objections: 3,
      handledWellPercentage: 90
    }
  ],
  "2023-10-04": [
    {
      name: "Jacob",
      type: "Summary",
      totalCalls: 5,
      overallScore: 90,
      handledWellPercentage: 85
    },
    {
      name: "Jacob",
      type: "Call",
      length: "18 minutes",
      objections: 6,
      handledWellPercentage: 83
    },
    {
      name: "Mike",
      type: "Summary",
      totalCalls: 7,
      overallScore: 88,
      handledWellPercentage: 72
    },
    {
      name: "Mike",
      type: "Call",
      length: "12 minutes",
      objections: 2,
      handledWellPercentage: 100
    }
  ]
};

export {
  followupData,
  sidebarSampleData,
  callSummaryData,
  householdNotes
}