import React from 'react';
import { useState, useEffect} from 'react';
import { GiPieChart } from "react-icons/gi";
import { PiMagnifyingGlass } from "react-icons/pi";
import { BsLayoutTextWindow } from "react-icons/bs";
import { LuListTodo } from "react-icons/lu";
import { saveEmailToFirebase } from './Firebase.js'
import Testimonials from './Testimonials.jsx'


const testimonials = [
  "This is fire. This is awesome guys",
  "Man, that's really cool. Do we need sales people when robots take over?",
  // "The insights from Fielder are invaluable for our daily operations.",
  // "Our sales reps have never been more prepared, all thanks to Fielder."
];

const attributions = [
  "Solar Sales Manager (Kansas)",
  "Solar Sales Manager (Califonia)",
  // "The insights from Fielder are invaluable for our daily operations.",
  // "Our sales reps have never been more prepared, all thanks to Fielder."
];

const imagePaths = [
  // "favicon.ico"
  "./LandingPageImages/image1.jpeg", // Replace with the path to your image
  "./LandingPageImages/image2.jpeg", // Replace with the path to your image
  "./LandingPageImages/image3.jpeg", // Replace with the path to your image
];



const LandingPage = () => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  const year = new Date().getFullYear();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'error' or 'success'

  useEffect(() => {
    let timeout;
    if (message && messageType === 'error') {
      timeout = setTimeout(() => {
        setMessage('');
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [message, messageType]);

  const validateEmail = (email) => {
    return email.match(
      // Regular expression to validate the email
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    );
  };

  const saveEmail = () => {
    if (validateEmail(email)) {
      // Here you would typically send the email to your backend or email service
      saveEmailToFirebase(email)
      setMessage("Thanks for signing up. We'll be in touch later today!");
      setMessageType('success');
      setEmail(''); // Clear the email bar
    } else {
      setMessage('Please enter a valid email address.');
      setMessageType('error');
    }
  };

  // Switch testimonial every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTestimonialIndex((testimonialIndex + 1) % testimonials.length);
    }, 5*1000);

    return () => clearInterval(interval);
  }, [testimonialIndex]);

    // Switch image every 3 seconds
  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((current) => (current + 1) % imagePaths.length);
    }, 10*1000);

    return () => clearInterval(imageInterval);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {/* Header Bar */}
      <div className="flex justify-between items-center p-4">
        {/* Left: Logo and Company Name */}
        <div className="flex items-center">
          <img src="logo.png" alt="Fielder Logo" className="h-10 mr-2" />
          <span className="font-bold text-lg">Fielder</span>
        </div>

        {/* Middle: Navigation Buttons */}
        <div className="hidden md:flex space-x-4">
          <a href="#product" className="text-gray-600 font-bold mx-2">Product</a>
          <a href="#about" className="text-gray-600 font-bold mx-2">About</a>
        </div>

        {/* Right: Try It Out Button */}
        <div>
        {/*<button
          className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-4 py-2 rounded-md font-bold transform transition-colors duration-500 hover:from-blue-700 hover:to-green-700 md:inline-block hidden"
          onMouseEnter={e => e.target.classList.add('scale-105')}
          onMouseLeave={e => e.target.classList.remove('scale-105')}
        >
          Try it out
        </button>*/}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col bg-gray-900 sm:flex-row sm:grow sm:relative">
        {/* Gradient removed on small screens using sm:bg-gradient-to-r */}
        <div className="z-10 absolute inset-0 sm:bg-gradient-to-r from-gray-900 sm:from-60% via-transparent sm:via-90% to-transparent sm:to-100%"></div>

        {/* Content */}
        <div className="z-10 p-8 sm:w-1/2 w-full order-2 sm:order-1">
          {/* Product Lead */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-white font-bold">
            Give your reps science-based coaching. Every day.
          </h1>

          {/* Email Field and Book Demo Button */}
          <div className="mt-8 flex flex-col text-center sm:text-left">
            <div className="flex-col text-center sm:text-left sm:flex-row w-full">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 mr-4 rounded w-full sm:w-3/5 sm:text-lg"
              />
              <button onClick={saveEmail} className="mt-4 bg-red-600 text-white px-4 py-2 rounded font-bold sm:text-lg">
                Book demo
              </button>
            </div>
            {message && (
              <div className='w-full'>
              <p
                className={`mt-2 text-sm ${messageType === 'error' ? 'text-red-600' : 'text-green-500'} transition-opacity duration-1000 text-center sm:text-left`}
                style={{ opacity: message ? 1 : 0 }}
              >
                {message}
              </p>
              </div>
            )}
          </div>
          {/* Testimonial Banner */}
          <div className="mt-8 p-4">
            <p className="text-white text-lg">{testimonials[testimonialIndex]}</p>
            <p className="text-white italic text-xs text-right">-- {attributions[testimonialIndex]}</p>
          </div>
        </div>
        {/* Carousel - order-1 on small screens makes it stack on top */}
        <div className="relative flex z-0 w-full sm:w-1/2 h-full order-1 sm:order-2">
                  
          {/* Image placeholder */}
          <img
            src={imagePaths[currentImage]}
            alt={`Carousel ${currentImage}`}
            className="absolute top-0 w-full h-full object-cover opacity-0 transition-opacity duration-1000 ease-in-out"
            style={{ opacity: 1 }}
          />
          {/* The below div might be for a background or another image. If it's not needed for the image scaling, it can be removed. */}
          <div className="absolute inset-0 bg-cover bg-no-repeat bg-center transition-opacity duration-1000 ease-in-out" style={{ backgroundImage: `url(/path-to-image-${testimonialIndex}.jpg)` }}></div>
        </div>
      </div>
      <div className="flex-col bg-white py-12 w-full">
        <Testimonials logos={['testimonial_headshots/1.png', 'testimonial_headshots/2.png', 'testimonial_headshots/3.png', 'testimonial_headshots/4.png', 'testimonial_headshots/5.png', 'testimonial_headshots/6.png']}/>
      </div>
      <div className="flex-col bg-white py-12 w-full">
        <div className="flex-col mb-8">
          <h3 className="text-blue-600 font-bold text-center">Personalized feedback for every rep. Instantly.</h3>
          <h2 id="product" className="text-3xl font-bold text-black text-center">Real customer conversations to coach your reps</h2>
        </div>

        <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-4">
          
          <div className="flex flex-row items-left">
            <div className='flex mr-4'>
              <div className='bg-blue-500 h-10 w-10 flex items-center justify-center rounded'>
                <GiPieChart color='white' size={20}/>
              </div>
            </div>
              <div className='flex-row'>
                <h4 className="text-lg font-semibold">Coaching plans for your reps</h4>
                <p>Turn hours of customer conversations into feedback plans for all your reps</p>
              </div>
          </div>
          
          <div className="flex flex-row items-left">
            <div className='flex mr-4'>
              <div className='bg-blue-500 h-10 w-10 flex items-center justify-center rounded'>
                <PiMagnifyingGlass color='white' size={20}/>
              </div>
            </div>
            <div className='flex-row'>
              <h4 className="text-lg font-semibold">Instantly analyze hours of conversations</h4>
              <p>Transform extensive conversation data into actionable feedback with our AI-driven analysis, freeing up time for strategic decision-making.</p>
            </div>
          </div>
          
          <div className="flex flex-row items-left">
            <div className='flex mr-4'>
              <div className='bg-blue-500 h-10 w-10 flex items-center justify-center rounded'>
                <BsLayoutTextWindow color='white' size={20}/>
              </div>
            </div>
            <div className='flex-row'>
              <h4 className="text-lg font-semibold">Share the wealth</h4>
              <p>Give all your reps access to a highlight reel of objection handling and feedback</p>
            </div>
          </div>
          <div className="flex flex-row items-left">
            <div className='flex mr-4'>
              <div className='bg-blue-500 h-10 w-10 flex items-center justify-center rounded'>
                <LuListTodo color='white' size={20}/>
              </div>
            </div>
            <div className='flex-row'>
              <h4 className="text-lg font-semibold">Automatic follow-ups and feedback</h4>
              <p>Get AI-generated follow-up messages after every customer conversation</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap -mx-4 justify-center">
            <div className="flex-col mb-8">
              <h2 id="about" className="text-3xl font-bold text-black text-center">Built with the whole team in mind</h2>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-10 md:mb-0 text-center">
              <img
                src="manager.png"
                alt="Built for managers"
                className="rounded-lg mb-4 mx-auto w-full md:w-80" // Changed to w-full for small screens and added mx-auto
              />
              <h2 className="text-2xl font-semibold mb-2">Built for managers...</h2>
              <p className="text-gray-600">
                Give all your reps the mentorship and coaching based on real customer interactions.
              </p>
            </div>
            <div className="w-full md:w-1/2 px-4 text-center">
              <img
                src="salesrep2.png"
                alt="...To turbocharge reps"
                className="rounded-lg mb-4 mx-auto w-full md:w-80" // Changed to w-full for small screens and added mx-auto
              />
              <h2 className="text-2xl font-semibold mb-2">...To turbocharge reps</h2>
              <p className="text-gray-600">
                Get immediate feedback on your pitch. Get evidence-based feedback every day. Close more deals sooner.
              </p>
            </div>
          </div>

        </div>
      </div>
      <footer className="text-center text-black py-4">
          <p>© {year} Fielder. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;